
import Vue from 'vue'
import Component from 'vue-class-component'

interface Item {
  title: string
  desc: string
}

@Component
export default class PremiumDescription extends Vue {
  items: Item[] = [
    {
      title: 'Access to premium content',
      desc:
        'Gain exclusive access to our latest and ever-growing collection of premium content, such ' +
        'as guides, todo lists, and premium articles, where detailed explanations are written by our team. ',
    },
    {
      title: 'Smart CRS calculator',
      desc:
        'Tired calculating points and checking eligibility? Calculate your points on the fly. ' +
        'Check your eligibility immediately. No more completing the IRCC profile to check your points and eligibility.',
    },
    {
      title: 'Saving profile',
      desc:
        'Premium users can save their profiles. All your progress will be saved. Save ' +
        'your time from filling forms and calculating it again.',
    },
    {
      title: 'Advanced guide',
      desc:
        'Todo lists, tips, explanations, and source links while you complete each step ' +
        'from Eligibility to PR.',
    },
    {
      title: 'Points improvement guide',
      desc:
        'Difficult to find out what to improve to get enough points? Set the target points ' +
        'and get suggestions on what exactly to improve to get enough points.',
    },
    {
      title: 'ITA documents',
      desc:
        "Don't wait till you get an Invitation to Apply! Prepare documents before you get " +
        'ITA. Supporting documents guide with todo lists.',
    },
    {
      title: 'COPR / Landing guides',
      desc:
        'Use our guide to get your Confirmation of Permanent Residence and make your ' +
        'landing smooth.',
    },
    {
      title: 'Reminders',
      desc: 'Set reminders, so that you dont miss any submission deadline.',
    },
  ]
}
