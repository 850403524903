
import Vue from 'vue'
import Component from 'vue-class-component'
import { Product, Plan, PlanUtils, SubscriptionUtils, SubscriptionInfo } from 'shared-entities'
import { Prop, Watch } from 'vue-property-decorator'
import SelectOption from '@/common/interfaces/select-option'
import store from '@/store/store'
import { AuthTypes } from '@/store/modules/auth.module'

@Component
export default class ProductView extends Vue {
  @Prop({ required: true })
  product!: Product
  @Prop({ type: String, default: null })
  submittingProductId!: string | null

  selectedIntervalId: string | null = null

  mounted() {
    this.determineSelectedInterval()
  }

  /** Get current subscription */
  private get subscription(): SubscriptionInfo | null {
    return store.getters[AuthTypes.getters.subscription]
  }

  /**
   * Set the selected interval to first non-disabled interval, or the
   * first interval in the list if all intervals are disabled.
   */
  private determineSelectedInterval() {
    const intervals = this.intervals
    const interval = intervals.find(it => !it.disabled)
    if (interval) {
      this.selectedIntervalId = interval.id
    } else {
      this.selectedIntervalId = intervals[0].id
    }
  }

  private get plansArray(): Plan[] | null {
    const plans = this.product.plans
    if (plans) {
      return Object.values(plans).sort((first, second) => first.price - second.price)
    }
    return null
  }

  /** Get list of intervals for the given product */
  get intervals(): SelectOption[] {
    const plans = this.plansArray
    if (plans) {
      return plans.map(plan => {
        return {
          id: plan.id,
          title: `$${plan.price / 100} ${this.$ts(PlanUtils.getIntervalTitle(plan))}`,
          disabled: this.isPlanDisabled(plan),
        }
      })
    }
    return []
  }

  /** Return true if the given plan should be disabled. */
  private isPlanDisabled(plan: Plan): boolean {
    const subscription = this.subscription
    const subPlan = subscription ? subscription.plan : null
    if (subPlan) {
      return subPlan.id === plan.id || plan.price < subPlan.price
    }
    return false
  }

  /** Get currently selected plan */
  get plan(): Plan | null {
    const planId = this.selectedIntervalId
    const plans = this.plansArray
    if (planId && plans) {
      const plan = plans.find(it => it.id === planId)
      return plan || null
    }
    return null
  }

  /** Calculate price per month for the currently selected plan. */
  private get pricePerMonth(): number | null {
    const plan = this.plan
    if (plan) {
      return PlanUtils.getPricePerMonth(plan)
    }
    return null
  }

  /** Return true if the user is currently subscribed to the current plan */
  get isSubscribed(): boolean {
    const sub = this.subscription
    return sub
      ? SubscriptionUtils.isSubscriptionActive(sub) && sub.productId === this.product.id
      : false
  }

  get subscribedPlanIntervalTitle(): string | null {
    const subscription = this.subscription
    const plan = subscription ? subscription.plan : null
    if (plan) {
      return PlanUtils.getIntervalTitle(plan)
    }
    return null
  }

  get pricePerMonthLabel(): string | null {
    const pricePerMonth = this.pricePerMonth
    if (pricePerMonth) {
      return `$${pricePerMonth / 100} ${this.$tc('interval.month', 1)}`
    }
    return null
  }

  get canUpgrade(): boolean {
    const sub = this.subscription
    return !this.isSubscribed && SubscriptionUtils.canCancelSubscription(this.subscription)
  }

  /** Return true if the entire product is disabled for selection */
  get isDisabled(): boolean {
    return this.intervals.every(it => it.disabled)
  }

  get isBtnDisabled(): boolean {
    return this.isDisabled || this.submittingProductId !== null
  }

  get isBtnLoading(): boolean {
    return this.submittingProductId === this.product.id
  }

  onBtnClick() {
    this.$emit('select', this.plan)
  }

  @Watch('subscription')
  private onSubscriptionChange() {
    this.determineSelectedInterval()
  }
}
