
import Vue from 'vue'
import Component from 'vue-class-component'

import ProductFeaturesTable from './ProductFeaturesTable.vue'
import ProductView from './ProductView.vue'
import { Product, Plan, SubscriptionUtils, Subscription } from 'shared-entities'
import ProductsRepository from '@/data/repository/products.repository'
import PaymentRepository from '@/data/repository/payment.repository'
import store from '@/store/store'
import { AuthTypes } from '@/store/modules/auth.module'
import PlanUpgradePreviewDialog from './PlanUpgradePreviewDialog.vue'
import RouteHelper from '@/ui/router/route-helper'
import RouteNames from '@/ui/router/route-names'

@Component({
  components: {
    ProductFeaturesTable,
    ProductView,
  },
})
export default class PlanSelector extends Vue {
  products: Product[] | null = null

  isLoading = false
  submittingProductId: string | null = null

  mounted() {
    this.init()
  }

  private async init() {
    this.isLoading = true
    const productsResult = await ProductsRepository.getProducts()
    this.isLoading = false

    if (productsResult.isSuccessful) {
      this.products = productsResult.value
    } else {
      this.$snackbar.show({ message: productsResult.errorMessage || 'Unknown error' })
    }
  }

  private get isSignedIn(): boolean {
    return store.getters[AuthTypes.getters.isSignedIn]
  }

  private get subscription(): Subscription | null {
    return store.getters[AuthTypes.getters.subscription]
  }

  async selectPlan(plan: Plan) {
    if (this.isSignedIn) {
      const sub = this.subscription
      if (sub && SubscriptionUtils.isSubscriptionActive(sub)) {
        if (sub.status === 'trialing') {
          this.confirmCancelTrial(() => {
            this.upgradePlan(plan)
          })
        } else {
          this.upgradePlan(plan)
        }
      } else {
        this.submittingProductId = plan.productId
        const result = await PaymentRepository.selectPlan(plan)
        this.submittingProductId = null
        if (!result.isSuccessful) {
          this.$snackbar.show({ message: result.errorMessage || 'Unknown error' })
        }
      }
    } else {
      RouteHelper.navigateToAuth(RouteNames.PLANS)
    }
  }

  private confirmCancelTrial(callback: () => void) {
    this.$dialog.confirm({
      title: 'Trial will be canceled',
      message:
        'If you upgrade your plan your current trial period will be canceled. ' +
        'Would you like to proceed?',
      confirmBtnText: 'Proceed',
      rejectBtnText: 'Close',
      onConfirm: callback,
    })
  }

  async upgradePlan(plan: Plan) {
    const product = this.products!.find(it => it.id === plan.productId)
    this.submittingProductId = plan.productId
    const previewResult = await PaymentRepository.previewSubscriptionUpdate(plan.id)
    this.submittingProductId = null
    if (previewResult.isSuccessful) {
      const preview = previewResult.value
      this.$dialog.show({
        component: PlanUpgradePreviewDialog,
        width: 450,
        props: {
          plan,
          productTitle: product ? product.title : '',
          preview,
        },
      })
    }
  }
}
