
import Vue from 'vue'
import Component from 'vue-class-component'

import { AuthTypes } from '@/store/modules/auth.module'
import {
  Subscription,
  SubscriptionClaim,
  Plan,
  PlanInterval,
  SubscriptionUtils,
  SubscriptionInfo,
  Coupon,
  PlanUtils,
} from 'shared-entities'
import RouteNames from '@/ui/router/route-names'
import UiUtils from '@/ui/util/ui-utils'
import ProductsRepository from '@/data/repository/products.repository'

import PremiumPlanCard from './PremiumPlanCard.vue'
import PremiumDescription from './PremiumDescription.vue'
import store from '@/store/store'
import PaymentRepository from '@/data/repository/payment.repository'
import RouteHelper from '@/ui/router/route-helper'
import PlanUpgradePreviewDialog from '../components/PlanUpgradePreviewDialog.vue'

@Component({
  head: {
    title: UiUtils.translateTitle('label.plans'),
  },
  components: {
    PremiumPlanCard,
    PremiumDescription,
  },
})
export default class PremiumScreen extends Vue {
  isLoading = false
  submittingPlanId: string | null = null
  plans: Plan[] = []

  mounted() {
    // Cannot perform any operations on plans if the current subscription has been scheduled
    // for cancellation at the end of the current billing period. So we take the user to the
    // profile page.
    const subscription = this.$store.getters[AuthTypes.getters.subscription] as Subscription
    if (subscription && subscription.cancelAtPeriodEnd) {
      this.$router.replace({ name: RouteNames.PROFILE })
    }

    this.init()
  }

  private async init() {
    this.isLoading = true
    try {
      const productsResult = await ProductsRepository.getProducts()
      if (productsResult.isSuccessful) {
        const premiumProduct =
          productsResult.value.find(it => it.claim === SubscriptionClaim.PREMIUM) || null

        if (premiumProduct) {
          this.plans = premiumProduct.plans ? Object.values(premiumProduct.plans) : []
          PlanUtils.sortByDuration(this.plans)
        }
      } else {
        this.$snackbar.show({ message: productsResult.errorMessage || 'Unknown error' })
      }
    } finally {
      this.isLoading = false
    }
  }

  async selectPlan(plan: Plan) {
    if (this.isSignedIn) {
      const sub = this.subscription
      if (sub && SubscriptionUtils.isSubscriptionActive(sub)) {
        if (sub.status === 'trialing') {
          this.confirmCancelTrial(() => {
            this.upgradePlan(plan)
          })
        } else {
          this.upgradePlan(plan)
        }
      } else {
        this.submittingPlanId = plan.id
        const result = await PaymentRepository.selectPlan(plan)
        this.submittingPlanId = null
        if (!result.isSuccessful) {
          this.$snackbar.show({ message: result.errorMessage || 'Unknown error' })
        }
      }
    } else {
      RouteHelper.navigateToAuth(RouteNames.PREMIUM)
    }
  }

  private get isSignedIn(): boolean {
    return store.getters[AuthTypes.getters.isSignedIn]
  }

  private get subscription(): SubscriptionInfo | null {
    return store.getters[AuthTypes.getters.subscription]
  }

  private confirmCancelTrial(callback: () => void) {
    this.$dialog.confirm({
      title: 'Trial will be canceled',
      message:
        'If you upgrade your plan your current trial period will be canceled. ' +
        'Would you like to proceed?',
      confirmBtnText: 'Proceed',
      rejectBtnText: 'Close',
      onConfirm: callback,
    })
  }

  async upgradePlan(plan: Plan) {
    this.submittingPlanId = plan.id
    const previewResult = await PaymentRepository.previewSubscriptionUpdate(plan.id)
    this.submittingPlanId = null
    if (previewResult.isSuccessful) {
      const preview = previewResult.value
      this.$dialog.show({
        component: PlanUpgradePreviewDialog,
        width: 450,
        props: {
          plan,
          productTitle: 'Premium',
          preview,
        },
      })
    } else {
      this.$snackbar.show({ message: previewResult.errorMessage })
    }
  }

  private isSubscribedTo(plan: Plan): boolean {
    const sub = this.subscription
    return sub ? sub.planId == plan.id : false
  }

  private isDowngrade(plan: Plan): boolean {
    const sub = this.subscription
    const subPlan = sub ? sub.plan : null
    if (subPlan) {
      return plan.price < subPlan.price
    }
    return false
  }

  get hasPlans(): boolean {
    return this.plans.length > 0
  }

  isSubscriptionDisabled(plan: Plan): boolean {
    return !!this.submittingPlanId || this.isSubscribedTo(plan) || this.isDowngrade(plan)
  }

  buttonText(plan: Plan): string {
    return this.isSubscribedTo(plan) ? 'Already subscribed' : 'Subscribe'
  }

  getIntervalTitle(plan: Plan): string {
    return PlanUtils.getIntervalTitle(plan)
  }
}
