
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import FormattingUtils from '@/common/util/formatting-utils'
import { Coupon, DiscountUtils, Plan, PlanUtils } from 'shared-entities'

/**
 * Events:
 * subscribe - when the Subscribe button is pressed by the user.
 */
@Component
export default class PremiumPlanCard extends Vue {
  @Prop({ required: true })
  plan!: Plan

  /** Shows the discount label if present */
  @Prop({ type: String })
  discountPercent?: string

  /** If true, then a spinner is shown on the Subscribe button */
  @Prop({ type: Boolean, default: false })
  submitting!: boolean

  /** If true, then the Subscribe button is disabled */
  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  /** Label for the Subscribe button. By default, 'Subscribe' is rendered. */
  @Prop({ type: String })
  buttonText?: string

  subscribe() {
    this.$emit('subscribe')
  }

  get title(): string {
    return PlanUtils.getIntervalTitle(this.plan)
  }

  get period(): string {
    return PlanUtils.getIntervalShortTitle(this.plan)
  }

  get coupon(): Coupon | null {
    return PlanUtils.getCoupon(this.plan)
  }

  get formattedPrice(): string {
    const percentOff = this.couponPercentOff
    let price = this.plan.price
    if (percentOff) {
      price = DiscountUtils.calculateDiscountedPrice(price, percentOff)
    }
    return FormattingUtils.formatNumeric(price / 100)
  }

  get couponPercentOff(): number | null {
    if (this.coupon) {
      return this.coupon.percentOff
    }
    return null
  }
}
