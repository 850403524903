
import { AuthTypes } from '@/store/modules/auth.module'
import RouteNames from '@/ui/router/route-names'
import UiUtils from '@/ui/util/ui-utils'
import { Subscription } from 'shared-entities'
import Vue from 'vue'
import Component from 'vue-class-component'
import PlanSelector from './components/PlanSelector.vue'

@Component({
  head: {
    title: UiUtils.translateTitle('label.plans'),
  },
  components: {
    PlanSelector,
  },
})
export default class PaymentScreen extends Vue {
  mounted() {
    const subscription = this.$store.getters[AuthTypes.getters.subscription] as Subscription
    if (subscription && subscription.cancelAtPeriodEnd) {
      this.$router.replace({ name: RouteNames.PROFILE })
    }
  }
}
