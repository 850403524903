
import Vue from 'vue'
import Component from 'vue-class-component'
import { Product } from 'shared-entities'
import { Prop, Watch } from 'vue-property-decorator'

@Component
export default class ProductFeaturesTable extends Vue {
  @Prop({ required: true })
  products!: Product[]
  features: string[] = []
  featureMap: Record<string, Record<string, boolean>> = {}

  mounted() {
    this.updateFeatures()
  }

  private updateFeatures() {
    this.features = []
    this.featureMap = {}
    for (let i = 0; i < this.products.length; i++) {
      const product = this.products[i]
      for (let j = 0; j < product.features.length; j++) {
        const feature = product.features[j]
        if (!this.featureMap[feature]) {
          this.features.push(feature)
          this.featureMap[feature] = {}
        }
        this.featureMap[feature][product.id] = true
      }
    }
  }

  @Watch('products')
  private onProductsChanged() {
    this.updateFeatures()
  }
}
